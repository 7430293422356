//import * as React from 'react';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import ClientConfig from '../../../config/clientConfig';
import { PrimaryButton, TextField } from '@fluentui/react'
import { IApplicationState } from '../../../store';
import { resetStoreState, setCurrentPage } from '../../../store/Flow/actions';
import { IFlowState } from '../../../store/Flow/types';
import { LocalizationDictionary } from '../../../localization/localizationDictionary';
import { ICustomerDataState } from '../../../store/CustomerData/types';
import { setCustomerData } from '../../../store/CustomerData/action';
import { ILocalizationState } from '../../../store/Localization/types';
import Keyboard from "react-simple-keyboard";

import './index.css';
import KeyboardWrapper from '../../LayoutElements/VirtualKeyboard/KeyboardWrapper';
import { ILayoutDataState, KeyboardLayoutDesign } from '../../../store/LayoutData/types';
import { IConfigDataState } from '../../../store/ConfigData/types';
import StylesUtil from '../../../utils/StylesUtil';
import { setCurrentDepartment } from '../../../store/LayoutData/actions';
import { SetStateAction } from 'react';
import { log } from 'console';

interface ILogo {
    logo: any;
}

interface IState {
    firstName: string;
    secondName: string;
    buttonDisabled: boolean;
    nameToDisplay: string;
}

interface IDispatchProps {
    setCurrentPage: typeof setCurrentPage;
    setCustomerData: typeof setCustomerData;
    resetStoreState: typeof resetStoreState;
    setCurrentDepartment: typeof setCurrentDepartment;
}

type Props = IDispatchProps & IFlowState & ICustomerDataState & ILocalizationState & ILogo & ILayoutDataState & IConfigDataState;

class MainFieldsPage extends React.Component<Props, IState> {

    private readonly strings = LocalizationDictionary.getStrings;
    private readonly keyboard: React.MutableRefObject<typeof Keyboard> = React.createRef();
    layoutName?: KeyboardLayoutType = 'upperCaseAlphabet'; // External layout control

    public readonly state: IState = {
        firstName: '',
        secondName: '',
        buttonDisabled: true,
        nameToDisplay: ''
    }


    private onKeyboardInputChange = (input: string) => {
        const { layoutData } = this.props;
        var names = input

        const namesSplit = input.split(' ')

        var firstName = namesSplit.shift();
        var secondName = namesSplit.join(' ');

        if (firstName && secondName) {
            this.setState({ firstName, secondName })
            names = firstName + " " + secondName
        }
        if (!firstName && this.state.firstName) {
            //reset value if not present, to ensure correct next button state
            this.setState({ firstName: "" })
        }
        if (!secondName && this.state.secondName) {
            //reset value if not present, to ensure correct next button state
            this.setState({ secondName: "" })
        }

        this.setState({ nameToDisplay: names })

        this.setButtonState();

        if (layoutData.code === "EDForhandler") {
            if (names.length === 0) {
                this.setState({ buttonDisabled: true });
            } else if (namesSplit.length === 1) {
                this.setState({ buttonDisabled: false, firstName: names, secondName: "" });
            } else if (namesSplit.length > 1) {
                this.setState({ buttonDisabled: false });
            }
        }
    }

    private nextButtonClicked = () => {
        const { currentFlow, data, setCustomerData, setCurrentPage, layoutData, conditionData, configData, departmentsData, language, languageData, setCurrentDepartment } = this.props;
        const { firstName, secondName } = this.state;
        var currentCondition = conditionData.find(({ localizationCode }) => localizationCode === language);
        var currentLanguageOptions = languageData.find(({ localizationCode }) => localizationCode === language);

        data.firstName = firstName;
        data.secondName = secondName;
        setCurrentPage("CustomFieldsPage" as PageType);
        setCustomerData(data);

        if (currentLanguageOptions && currentLanguageOptions.customFieldsPageEnabled && layoutData.code !== "Heimdalls") {
            setCurrentPage("CustomFieldsPage" as PageType);
        }
        else {
            switch (currentFlow) {
                case "CheckinAppointment":
                    if (layoutData.code === "Sef" || layoutData.code === "Seftest") {
                        setCurrentPage("CustomFieldsPage" as PageType);
                        break;
                    } else if (layoutData.code === "DSStaal") {
                        setCurrentPage("CustomFieldsPage" as PageType);
                        break;
                    } else if (configData.useClientDepartments && departmentsData && departmentsData.length > 0) {
                        if (departmentsData.length === 1) {
                            setCurrentDepartment(0);
                            setCurrentPage("EmployeePage" as PageType);
                        } else {
                            setCurrentPage("DepartmentPage" as PageType);
                        }
                        break;
                    } else if (layoutData.code === "Heimdalls" || layoutData.code === "FibiaMuseum") {
                        setCurrentPage("MessagePage" as PageType)
                        break;
                    } else if (layoutData.code === "Meneta" || layoutData.code === "FriskSnit") {
                        setCurrentPage("CustomFieldsPage" as PageType);
                        break;
                    } else {
                        setCurrentPage("EmployeePage" as PageType);
                        break;
                    }
                case "CheckinNoAppointment":
                    if (ClientConfig.flow.informAllEnabled && layoutData.code !== "Heimdalls") {
                        if (currentCondition && currentCondition.useVideo && currentCondition.videoURL !== null && currentCondition.videoURL.trim() !== "") {
                            setCurrentPage("ConditionVideoPage" as PageType);
                        } else {
                            setCurrentPage("MessagePage" as PageType);
                        }
                    }
                    else if (layoutData.code === "Sef" || layoutData.code === "Seftest" || layoutData.code === "Heimdalls") {
                        setCurrentPage("CustomFieldsPage" as PageType);
                        break;
                    } else if (configData.useClientDepartments && departmentsData && departmentsData.length > 0) {
                        if (departmentsData.length === 1) {
                            setCurrentDepartment(0);
                            setCurrentPage("EmployeePage" as PageType);
                        } else {
                            setCurrentPage("DepartmentPage" as PageType);
                        }
                        break;
                    } else if (layoutData.code === "Meneta") {
                        setCurrentPage("CustomFieldsPage" as PageType);
                        break;
                    } else {
                        setCurrentPage("EmployeePage" as PageType);
                        break;
                    }
                    break;
                case "GroupCheckin":
                    setCurrentPage("CustomFieldsPage" as PageType)
                    break;
                case "LeverandoerCheckin":
                    setCurrentPage("CustomFieldsPage" as PageType)
                    break;
                case "Carpenter":
                    if (layoutData.code === "Meneta") {
                        setCurrentPage("CustomFieldsPage" as PageType);
                        break;
                    } else if (layoutData.code === "DSStaal") {
                        setCurrentPage("CustomFieldsPage" as PageType);
                        break;
                    }
                    setCurrentPage("CustomFlowcardPage" as PageType);
                    break;
                case "Other":
                    if (layoutData.code === "Meneta") {
                        setCurrentPage("CustomFieldsPage" as PageType)
                        break;
                    }
                default:
                    throw Error('Unknown error.');
            }
        }
    }

    private setButtonState = () => {
        const { buttonDisabled, firstName, secondName } = this.state;

        if (firstName && secondName && firstName.trim() && secondName.trim()) {
            if (buttonDisabled) {
                this.setState({ buttonDisabled: false });
            }
        }
        else {
            if (!buttonDisabled) {
                this.setState({ buttonDisabled: true });
            }
        }
    }

    private onNameChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newName?: string) => {
        const { layoutData } = this.props
        this.onKeyboardInputChange(newName);
        if (layoutData.code !== "Dragsbaek") {
            (this.keyboard.current as any).setInput(newName);
        }
    }

    private addStyleButton(): any {
        const { layoutData } = this.props

        if (layoutData.code === "SociatyOfLife") {
            return ({ position: 'absolute', top: 400, left: 1500 })
        } else if (layoutData.code === "OfficePartner") {
            return ({ position: 'absolute', top: '31.5%', left: '82%' })
        } else if (layoutData.code === "EDForhandler") {
            return ({ position: 'absolute', top: '38%', left: '93%' })
        } else if (layoutData.code === "BirchEjendomme") {
            return ({ position: 'absolute', top: '302px', right: '50px' })
        }

    }

    private addPlaceHolder(): any {
        const { layoutData } = this.props

        if (layoutData.code === "SociatyOfLife") {
            return "Full name"
        } else if (layoutData.code === "OfficePartner") {
            return this.strings().namePlaceholderExample
        } else if (layoutData.code === "EDForhandler") {
            return this.strings().namePlaceholderED
        } else {
            return this.strings().namePlaceholder
        }
    }

    private addMainFieldsPageTitle() {
        const { logo, layoutData } = this.props

        if (layoutData.code === "OfficePartner") {
            return (<h2 style={{ position: 'absolute', top: '25%' }}>{this.strings().mainfieldsPageTitle}</h2>)
        } else if (layoutData.code === "EDForhandler") {
            return (<h2 style={{ color: ClientConfig.mainColor, fontWeight: 'bolder', position: 'absolute', top: '12%' }}>{this.strings().edInserValue}</h2>)
        } else if (layoutData.code === "Fundamentet") {
            return (<h2 style={{ color: ClientConfig.mainTextColor, position: 'relative', top: 50, minWidth: 370 }}>Klik hvad du skal i dag:</h2>)
        } else if (layoutData.code === "Reisswolf") {
            // TODO: Special logo case
            return (<div>
                <img className="logo" src={logo} onClick={this.iconClick} alt="Logo" />
                <h2 className="Reisswolf-title" style={{ color: ClientConfig.mainColor }}>{this.strings().checkinreisswolf}</h2>
                <h2 className="Reisswolf-secondtitle" style={{ color: ClientConfig.mainColor, fontFamily: "Inter" }}>{this.strings().reisswolftCheckinSecondtitle}</h2>
                <h3 className="Reisswolf-inputtext" style={{ color: ClientConfig.mainColor, fontFamily: "Inter" }}>{this.strings().reisswolftInputName}<span style={{ color: "red" }}>*</span></h3>
            </div>)
        }
    }

    private addKeyboard() {
        const { layoutData } = this.props

        if (layoutData.code === "OfficePartner") {
            return (<div style={{ width: 1010, position: 'absolute', left: 35, top: '40%' }}> <KeyboardWrapper keyboardRef={this.keyboard} onChange={this.onKeyboardInputChange} input={this.state.nameToDisplay} /></div>);
        } else if (layoutData.code === "EDForhandler") {
            return (<div style={{ position: 'absolute', top: '55%', width: '100%' }}> <KeyboardWrapper keyboardRef={this.keyboard} onChange={this.onKeyboardInputChange} input={this.state.nameToDisplay} /></div>);
        } else if (layoutData.code === "Fundamentet" || layoutData.code === "Dragsbaek") {

        } else if (layoutData.code === "Reisswolf") {
            return (<div style={{ marginTop: 200 }}> <KeyboardWrapper keyboardRef={this.keyboard} onChange={this.onKeyboardInputChange} input={this.state.nameToDisplay} /></div>);
        } else if (layoutData.code === "BirchEjendomme") {
            return (<div style={{ position: 'relative', top: '80px' }}> <KeyboardWrapper keyboardRef={this.keyboard} onChange={this.onKeyboardInputChange} layoutDesign={KeyboardLayoutDesign.SMALL} input={this.state.nameToDisplay} /></div>);
        } else {
            return (<KeyboardWrapper keyboardRef={this.keyboard} onChange={this.onKeyboardInputChange} input={this.state.nameToDisplay} />);
        }
    }

    private addTextField(nameToDisplay: any) {
        const { layoutData } = this.props

        if (layoutData.code === "OfficePartner") {
            return (<div style={{ top: '28.5%', position: 'absolute' }}>
                <TextField
                    autoComplete="new-password"
                    aria-autocomplete="none"
                    styles={StylesUtil.getTextFieldStyles(layoutData, false)}
                    className="main-text-field"
                    value={nameToDisplay}
                    onChange={this.onNameChange}
                    underlined={ClientConfig.layoutVersion === 'v1' ? false : true}
                    placeholder={this.addPlaceHolder()}
                    autoFocus /> </div>);

        } else if (layoutData.code === "EDForhandler") {
            return (<div style={{ top: '25%', position: 'absolute' }}>
                <TextField
                    autoComplete="new-password"
                    aria-autocomplete="none"
                    styles={StylesUtil.getTextFieldStyles(layoutData, false)}
                    className="main-text-field"
                    value={nameToDisplay}
                    onChange={this.onNameChange}
                    underlined={ClientConfig.layoutVersion === 'v1' ? false : true}
                    placeholder={this.addPlaceHolder()}
                    autoFocus /> </div>);

        } else if (layoutData.code === "Fundamentet") {

        } else if (layoutData.code === "Reisswolf") {
            return (<div className="Reiswolf-txtfield">
                <TextField
                    autoComplete="new-password"
                    aria-autocomplete="none"
                    styles={StylesUtil.getTextFieldStyles(layoutData, false)}
                    className="main-text-field"
                    value={nameToDisplay}
                    onChange={this.onNameChange}
                    underlined={ClientConfig.layoutVersion === 'v1' ? false : true}
                    autoFocus
                /></div>);
        } else {
            return (<TextField
                autoComplete="new-password"
                aria-autocomplete="none"
                styles={StylesUtil.getTextFieldStyles(layoutData, false)}
                className="main-text-field"
                value={nameToDisplay}
                onChange={this.onNameChange}
                underlined={ClientConfig.layoutVersion === 'v1' ? false : true}
                placeholder={this.addPlaceHolder()}
                autoFocus />);
        }
    }

    private addNextButton(buttonDisabled: boolean) {
        const { layoutData } = this.props

        if (layoutData.code === "Fundamentet") {

        } else if (layoutData.code === "Reisswolf") {
            return (
                <button className="continue2-button" style={buttonDisabled ? { fontFamily: 'Inter', backgroundColor: 'rgb(243, 242, 241)', color: 'rgb(210, 208, 206)' } : { fontFamily: 'Inter', backgroundColor: ClientConfig.mainColor, color: ClientConfig.mainTextColor }} onClick={this.nextButtonClicked} disabled={buttonDisabled}>{this.strings().continue}</button>
            )
        } else {
            return (<div className="main-fields-button-container">
                <PrimaryButton style={this.addStyleButton()} styles={StylesUtil.getButtonStyles(layoutData)} className="custom-fields-button" text={this.strings().next} onClick={this.nextButtonClicked} disabled={buttonDisabled} allowDisabledFocus />
            </div>);
        }
    }

    private clickFundamentetButton(chosen: string) {
        const { data, setCustomerData, setCurrentPage } = this.props;

        data.firstName = chosen;
        data.secondName = "";
        setCustomerData(data);

        setCurrentPage("EmployeePage" as PageType);
    }

    private iconClick = () => {
        const { resetStoreState } = this.props;

        resetStoreState();
    }

    private styleFundamentetButtons(): React.CSSProperties {
        return ({
            fontSize: '17px',
            fontWeight: 'bolder',
            backgroundColor: ClientConfig.mainColor,
            color: ClientConfig.mainTextColor,
            width: 300,
            margin: 15,
            height: 160,
            display: 'inline-block',
            textAlign: 'center',
            padding: 20,
            paddingTop: '6.5%',
            border: '1px solid black',
            borderRadius: 10,
        })
    }

    private AddFundametetButtons() {
        const { layoutData } = this.props

        if (layoutData.code === "Fundamentet") {
            return (<div>
                <div style={{ height: 205, marginTop: 60 }}>
                    <div style={this.styleFundamentetButtons()} onClick={() => this.clickFundamentetButton(this.strings().behandling)}>{this.strings().behandling}</div>
                    <div style={this.styleFundamentetButtons()} onClick={() => this.clickFundamentetButton(this.strings().fællesskab)}>{this.strings().fællesskab}</div>
                    <div style={this.styleFundamentetButtons()} onClick={() => this.clickFundamentetButton(this.strings().mentorsamtale)}>{this.strings().mentorsamtale}</div>
                </div>
                <div style={{ height: 205 }}>
                    <div style={this.styleFundamentetButtons()} onClick={() => this.clickFundamentetButton(this.strings().praktikshjælp)}>{this.strings().praktikshjælp}</div>
                    <div style={this.styleFundamentetButtons()} onClick={() => this.clickFundamentetButton(this.strings().hf)}>{this.strings().hf}</div>
                    <div style={this.styleFundamentetButtons()} onClick={() => this.clickFundamentetButton(this.strings().frivillig)}>{this.strings().frivillig}</div>
                </div>
            </div>
            )
        }
    }



    render() {
        const { currentPage } = this.props;
        const { buttonDisabled, nameToDisplay } = this.state;

        if (currentPage === "MainFieldsPage") {
            return (
                <div className="main-fields-container">
                    <div className="main-text-fields-container">
                        {this.addMainFieldsPageTitle()}
                        {this.addTextField(nameToDisplay)}
                        {this.AddFundametetButtons()}
                    </div>
                    {this.addNextButton(buttonDisabled)}
                    {this.addKeyboard()}

                </div>
            );
        }
        else {
            return (<div>Error</div>);
        }

    }
}

const mapStateToProps = (state: IApplicationState): Partial<IFlowState & ICustomerDataState & ILocalizationState & ILayoutDataState & IConfigDataState> => {
    const { currentPage, currentFlow } = state.flow;
    const { language } = state.localization;
    const { data } = state.customerData;
    const { layoutData, conditionData, departmentsData } = state.layoutData;
    const { configData, languageData } = state.configData;

    return {
        currentPage, currentFlow, data, language, layoutData, conditionData, configData, departmentsData, languageData
    };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
    return bindActionCreators(
        { setCurrentPage, setCustomerData, resetStoreState, setCurrentDepartment },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MainFieldsPage);
