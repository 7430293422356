import * as React from 'react';
//changed by adding * as,, the syntax change seemed to have fixed issue ts 1259 can only be default-imported using the 'esmoduleinterop' flag
import moment from 'moment'
import ClientConfig from '../../../config/clientConfig'

import './index.css'
import { IApplicationState } from "../../../store";
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ILayoutDataState } from "../../../store/LayoutData/types";
import StylesUtil from '../../../utils/StylesUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faClock } from '@fortawesome/free-solid-svg-icons';

interface IState {
    time: string;
    date: string;
}

interface IDispatchProps {
}

interface IProps {
}

type Props = IDispatchProps & ILayoutDataState & IProps & IState;

export class DigitalClock extends React.Component<Props, IState> {
    constructor(props: any) {
        super(props);

        this.state = {
            time: '',
            date: ''
        };

        this.timeout = setTimeout(this.step, this.interval);
    }

    private interval = 1000;
    private expected = Date.now() + this.interval;

    private updateDateTime = () => {
        const dateTime = new Date();
        this.setState({
            date: dateTime.toDateString(),
            time: dateTime.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' })
        });
    }

    private step = () => {
        var dt = Date.now() - this.expected; // the drift (positive for overshooting)
        if (dt > this.interval) {

        }

        this.updateDateTime();

        this.expected += this.interval;
        setTimeout(this.step, Math.max(0, this.interval - dt)); // take into account drift
    }

    private timeout: ReturnType<typeof setTimeout>;

    public componentWillUnmount = () => {
        clearTimeout(this.timeout);
    }

    render() {
        const { layoutData } = this.props;
        const { date, time } = this.state;

        if (layoutData.code === "DSStaal") {
            return (
                <div style={StylesUtil.getClockStyle(layoutData)} className={`clock-container clock-${ClientConfig.layoutVersion}`} >
                    <div style={StylesUtil.getClockTimeStyle(layoutData)} className="clock-time">
                        <FontAwesomeIcon icon={faClock} style={{ marginRight: '10px' }} />
                        {time ? time : `${new Date().toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' })}`}
                    </div>

                    <div style={StylesUtil.getClockDateStyle(layoutData)} className="clock-date">
                        <FontAwesomeIcon icon={faCalendarDays} style={{ marginRight: '10px' }} />
                        {date ? `${moment(date, 'ddd MMM DD YYYY').format('DD.MM.YYYY')}` : `${moment(new Date()).format('DD.MM.YYYY')}`}
                    </div>
                </div>
            );
        } else

            return (
                <div style={StylesUtil.getClockStyle(layoutData)} className={`clock-container clock-${ClientConfig.layoutVersion}`} >
                    <div style={StylesUtil.getClockTimeStyle(layoutData)} className="clock-time">
                        <p>{time ? time : `${new Date().toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' })}`}</p>
                    </div>

                    <div style={StylesUtil.getClockDateStyle(layoutData)} className="clock-date">
                        {date ? `${moment(date, 'ddd MMM DD YYYY').format('DD-MM-YYYY')}` : `${moment(new Date()).format('DD-MM-YYYY')}`}
                    </div>
                </div>
            );
    }
}


const mapStateToProps = (state: IApplicationState): Partial<ILayoutDataState> => {
    const { layoutData } = state.layoutData;

    return {
        layoutData
    };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
    return bindActionCreators(
        {},
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DigitalClock);
